.iconSearch {
  cursor: pointer;
}

.input {
  padding: 0;
  font-size: 16px;
  line-height: 40px;
  width: env(--space-200);
  border: 0;
  border-bottom: env(--space-2) solid hsl(env(--color-grey-30));
  background-color: transparent;
  padding: 0 env(--space-8);

  &:focus {
    border-bottom: env(--space-2) solid hsl(env(--color-grey-40));
  }

  &::placeholder {
    color: hsl(env(--color-grey-30));
  }


}
