.container {
  border-radius: 0;

  &[data-background='burgundy'] {
    background-color: hsl(env(--color-support-burgundy));
   color: hsl(env(--color-support-white));
  }

  &[data-background='peach'] {
    background-color: hsl(env(--color-support-peach));
    color: hsl(env(--color-support-black));
  }
}

.wrapper {
  margin: 0 auto;
  max-width: calc(env(--space-144)*8);
  flex-direction: column-reverse;
  padding: 0 env(--space-32);
}

.heading {
  overflow-wrap: normal;
}

.textAndButton {
  align-items: center;
}

.button {
  padding-bottom: env(--space-40);
}

.image {
  padding: -env(--space-32);
  position: relative;
  block-size: calc(env(--space-160)*2);
  inline-size: calc(env(--space-160)*2);
}

.title {
  max-width: calc(env(--space-120)*3.14) !important;
}

.description {
  max-width: calc(env(--space-120)*3.14);
}

@media (min-width: env(--breakpoint-small)) {
  .wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .textAndButton {
    align-items: var(--horizontalAlign);
  }

  .image {
    position: relative;
    block-size: calc(env(--space-200)*2);
    inline-size: calc(env(--space-200)*2);
  }
}
