.main {
  /* to add new color once inserted */
  background-color: hsl(env(--color-support-support-light));
  padding: env(--space-24);
  justify-content: space-between;
}

.image {
  display: none;
}

.icon {
  cursor: pointer;
  right: env(--space-24);
  position: absolute;
}

@media (min-width: env(--breakpoint-small)) {
  .main {
    /* to add new color once inserted */
    background-color: hsl(env(--color-support-support-light));
    max-height: env(--space-160);
    padding: env(--space-24) env(--space-24) env(--space-24) env(--space-80);
  }
  .icon {
    position: relative;
  }

  .texts {
    /* justify-content: space-between; */
    /* to add more distancing once spacing is added */
    max-width: calc(env(--space-200) * 3.14);
  }

  .image {
    display: block;
  }
}
