.contactSupport {
  overflow: hidden;
  justify-content: center;
  background-color: hsl(env(--color-support-white));
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: env(--space-24);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;

  max-width: env(--breakpoint-extra-small);
}

.label {
  /* Color to change */
  color: hsl(env(--color-support-brand));
  padding-bottom: env(--space-12);
}

.title {
  padding-bottom: env(--space-24);
  color: hsl(env(--color-support-black)) !important;
}

.image {
  display: flex;
  /* height: env(--space-8); */
  position: relative;
  /* width: 100vw; */
}

.card {
  max-width: calc(env(--space-144) * 7 / 2);
  padding: env(--space-40) env(--space-24);
}

@media (min-width: env(--breakpoint-small)) {
  .section {
    flex-direction: row;
    max-width: calc(env(--space-144) * 7);
    gap: env(--space-40);
    justify-content: space-between;
  }

  .card {
    max-width: calc(env(--space-72) * 7);
    padding: env(--space-80) 0;
  }

  .image {
    display: flex;
    width: 50vw;
    overflow: hidden;
    position: relative;
    height: calc(env(--space-200) * 2.5);
  }
}
