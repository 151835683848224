.main {
    display: flex;
    padding: env(--space-24);
    /* justify-content: center; */
    /* margin: 0 auto; */
    flex-direction: column;
    background-color: hsl(env(--color-grey-5));
    flex: 1 1 0;

    &>div {
        padding: env(--space-60) env(--space-24);
    }
}

@media (min-width: env(--breakpoint-medium)) {
    .main {
        overflow-x: scroll;

        &>div {
            padding: env(--space-60) 0;
                max-width: calc(env(--space-200)*5);
                margin: auto;
        }
    }
}
