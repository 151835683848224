.tab {
  padding: 0% !important;
  background-color: hsl(env(--color-support-white));
  color: hsl(env(--color-support-black));
  font-weight: 400;

  border-radius: env(--space-8);
  padding: env(--space-12) env(--space-16) !important;
  border: env(--space-2) solid hsl(env(--color-grey-20));

  &[data-active="true"] {
    background-color: hsl(env(--color-support-black));
    border: env(--space-2) solid hsl(env(--color-support-black));
    color: hsl(env(--color-support-white));

    &:hover , &:focus , &:active{
      color: hsl(env(--color-support-white));
    }
  }
}
