.dropDownMonths {
  position: absolute;
  width: calc(env(--space--200)*2);
  padding: 0 env(--space-16);
  overflow: hidden;
  background-color: hsl(env(--color-support-white));
  border-radius: env(--radius-4);
  border: 1px solid #E6E7E8;
  box-shadow: 0px 4px 20px rgba(15, 15, 15, 0.12);
  max-height: env(--space-200);
  overflow-y: scroll;
}

.item {
  padding: env(--space-16) 0;
cursor: pointer;

  &[data-selected=true]{
    color: hsl(env(--color-support-brand))
  }
}
