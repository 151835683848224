.ChooseRole {
  background-color: hsl(env(--color-grey-5));
  border: env(--space-2) solid hsl(env(--color-grey-10));
  border-radius: env(--radius-10);
  color: hsl(env(--color-support-black));
  flex: 50%;
  padding: env(--space-24);
    font-size: env(--font-size-14)!important;

  
  &[data-checked="true"] {
    color: hsl(env(--color-support-white));

    background-color: hsl(env(--color-support-burgundy));
  }
}

.imageContainer {
  border-radius: 45%;
  background-color: hsl(env(--color-support-white));
  padding: env(--space-24) env(--space-16);
  box-shadow: 0px env(--space-4) env(--space-24) rgba(15, 15, 15, 0.12);

  &[data-checked="true"] {
    filter: brightness(85%);

    background-color: hsl(env(--color-support-burgundy));
  }
}

/* [data-checked='true'] {
  &.imageContainer {
    background-color: hsl(env(--color-support-burgundy));
  }
} */

.image {
  block-size: env(--space-32);
  inline-size: env(--space-32);
  position: relative;
}

.checkbox {
  block-size: env(--space-24);
  inline-size: env(--space-24);
  border-radius: 50%;
  border: env(--space-2) solid hsl(env(--color-support-black));

  &[data-checked="true"] {
    background-color: hsl(env(--color-support-white));
    border: env(--space-2) solid hsl(env(--color-support-white));
  }
}
