.nav {
  /* height: 50px; */
  /* padding: env(--space-8) env(--space-40); */
  background: hsl(env(--color-support-white));
  color: hsl(env(--color-support-black));
  display: flex;
  flex-direction: row;

  /* position: absolute; */
  /* align-items: center; */
  justify-content: space-between;
  border-right: env(--space-2) solid hsl(env(--color-grey-10));
  padding: 0;

  & .linkLabel {
    opacity: 0;
    display: none;
  }
}

.nav ul {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  list-style: none;
  padding: 0;
  width: 100%;
  justify-content: space-around;
  padding: 0;
}

.link {
  margin: 0;
  cursor: pointer;
  padding: env(--space-8);
  margin: env(--space-8) 0;
  transition: background-color 100ms linear 0s;
  background-color: hsl(env(--color-support-white));
  border-radius: env(--space-8);
  display: flex;
  position: relative;
  gap: env(--space-16);

  &:hover {
    background-color: hsl(env(--color-grey-10));
  }

  /* &[data-active=true]{
    background-color: hsl(env(--color-grey-10));
  } */
}

.cardDescription {
  margin-bottom: env(--space-24);
  padding-bottom: env(--space-16);
  display: none;
  border-bottom: env(--space-2) solid hsl(env(--color-grey-10));


}

.linkLabel {
  opacity: 0;
  display: none;
}

.activator span {
  display: flex;
  flex-direction: row;
}

.sidebarButton {
  display: none;
}

.bottom {
  display: none;
}

.companyDropdown {
  display: none;
}

.menuElements {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  width: 100%;
}

@media (min-width: env(--breakpoint-medium)) {
  .nav {
    height: calc(100vh - env(--space-64));
    flex-direction: column;
    border-right: env(--space-2) solid hsl(env(--color-grey-10));

    transition: width 0.3s;

    &[data-open='true'] {
      min-width: calc(env(--space-200) * 3 / 2);
      /* width: 100%; */

      & .linkLabel,
      & .cardDescription {
        /* padding-left: env(--space-16); */
        display: block;
        opacity: 1;
        transition: display 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
      }

      &>.sidebarButton {
        left: calc(env(--space-184) * 3 / 2);
      }
    }

    &[data-open='false'] {
      width: env(--space-80);

      &>.sidebarButton {
        left: env(--space-56);
      }

      &>ul {
        padding-left: env(--space-16);
      }

      & .linkLabel {
        opacity: 0;
        display: none;
      }

      &>ul {
        padding-left: env(--space-8);
        padding-right: env(--space-4);
      }

      & .link {
        justify-content: center;
        padding: env(--space-16) env(--space-16);
      }
    }

    &:hover>.sidebarButton {
      opacity: 1;
    }
  }

  .nav ul {
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    list-style: none;
    padding: env(--space-40) env(--space-24) 0 env(--space-24);
    height: 100%;
    justify-content: space-between;
    height: 100vh;

  }


  .menuElements {
    flex-direction: column;
  }

  .companyDropdown {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: env(--space-8);
    margin-bottom: env(--space-12);
  }

  .bottom {
    display: block;
    padding-top: env(--space-16);
    display: flex;
    border-top: env(--space-2) solid hsl(env(--color-grey-10));
    align-items: center;
    cursor: pointer;
    gap: env(--space-12);
    bottom: 0;
    /* position: absolute; */
  }

  .sidebarButton {
    display: block;
    bottom: env(--space-16);
    position: absolute;
    opacity: 0;
    z-index: 10;
    transition: background-color 100ms linear 0s, color 100ms linear 0s,
      opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
      transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s, left 0.3s;
  }

  &:hover>.sidebarButton {
    opacity: 1;
  }

  .linkLabel {
    /* padding-left: env(--space-16); */
    opacity: 1;
    display: block;
    transition: display 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
      opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  }

  .link {
    padding: env(--space-16) env(--space-24);

    margin: 0;
  }
}

.tooltipContent {
  border-radius: env(--space-8);
  padding: env(--space-12) env(--space-16);
  font-size: 15;
  line-height: 1;
  z-index: 1000;
  color: black;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;

    &[data-state="delayed-open"] {
      &[data-side="top"] {
        animation-name: slideDownAndFade
      }

      &[data-side="right"] {
        animation-name: slideLeftAndFade
      }

      &[data-side="bottom"] {
        animation-name: slideUpAndFade
      }

      &[data-side="left"] {
        animation-name: slideRightAndFade
      }
    }
  }

  & .tooltipArrow {
    fill: white
  }
}
