.container {
  min-height: calc(100vh - env(--space-64));
  display: flex;
  overflow-x: scroll;
  flex-direction: column;
}

.page {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

@media (min-width: env(--breakpoint-medium)) {
  .container {
    flex-direction: row;
  }

  .page{
    height: calc(100vh - env(--space-64)) ;
    overflow-y: scroll;
  }
}
