.phoneNumberWrapper {
  align-items: center;
}

.inputNumber {
  margin-bottom: 0 !important;
}

.form fieldset,
.form>div>fieldset {
  margin: env(--space-24) 0 !important;
  display: initial;
}

.buttons {
  margin-top: env(--space-32)
}
