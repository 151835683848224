.MainFeature {
  display: flex;
  justify-content: center;
  background-color: hsl(env(--color-grey-10));
  flex-direction: column;
  align-items: center;
  padding: env(--space-80) 0;
  overflow: hidden;
}

.title {
  max-width: env(--breakpoint-extra-small);
  padding: 0 env(--space-24) env(--space-40) env(--space-24);
  display: flex;
  /* padding-bottom: env(--space-40); */
  width: 100%;
}

.section {
  display: flex;
  flex-wrap: wrap;
  gap: env(--space-40);
  flex-direction: column;
  justify-content: center;
  max-width: env(--breakpoint-extra-small);
  margin: 0 env(--space-24);
}

.FeatureCard {
  background-color: white;
  flex: 1 0 50%;
  /* max-width: calc(env(--space-80)*7); */
  background-color: #ffffff;
  padding: env(--space-40) env(--space-32);
  box-shadow: 0px 10px 40px -5px rgba(15, 15, 15, 0.12);
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.cardLabel {
  margin-top: env(--space-24);
  color: hsl(env(--color-support-brand));
}

.cardTitle {
  margin-top: env(--space-10);
}

.cardTitle,
.cardDescription {
  margin-top: env(--space-16);
}

.cardDescription{
  color: hsl(env(--color-grey-50));

}

.image{
  block-size: env(--space-200);
  inline-size: env(--space-200);
  position: relative;
  margin: 0 auto;
}


@media (min-width: env(--breakpoint-small)) {
  .MainFeature {
    padding: env(--space-160) 0;
  }


  .FeatureCard {
    flex: 1 0 33%;
    padding: env(--space-80) env(--space-96);
  }

  .section {
    display: flex;
    flex-wrap: wrap;
    gap: env(--space-40);

    flex-direction: row;
    justify-content: center;
    max-width: calc(env(--space-144) * 7);
  }

  .title {
    margin: 0 auto;
    padding: 0 0 env(--space-40) 0;
    /* padding-bottom: env(--space-40); */

    max-width: calc(env(--space-144) * 7);
  }
}
