.container{
    padding: env(--space-12) env(--space-24) ;
    /* padding-bottom: env(--space-8); */
        min-width: calc(env(--space-200)*3/2);
        max-width: calc(env(--space-200)*3);
}

.roles{
    flex-direction: column;
}

.disclaimer{
    color: hsl(env(--color-grey-50));
}

.input{
    margin-bottom: env(--space-40);
}


@media (min-width: env(--breakpoint-small)) {

.roles{
    flex-direction: row;
}
}