.wrapper {
  flex-direction: column;
  background-color: hsl(env(--color-grey-0));
  border-radius: env(--radius-10);
  padding: env(--space-32) env(--space-24);
  border: env(--space-2) solid hsl(env(--color-grey-10));
}

.userBarWrapper, .emptyStateUsers {
  padding: env(--space-12);
  border-radius: env(--radius-10);
  background-color: hsl(env(--color-grey-5));
  width: 100%;
  min-height: env(--space-56);
}

.avatar {
  padding: env(--space-4);
  background-color: hsl(env(--color-support-white));
  border-radius: env(--radius-4);
}

.userHours {
  color: hsl(env(--color-support-brand));
}

.progressContainer {
  width: 40%;
}

.usersContainer {
  max-height: calc(env(--space-200)*2);
  overflow-y: scroll;
}

.progress {
  width: env(--space-40);
  height: env(--space-8);
  background-color: hsl(env(--color-support-support-light));
  border-radius: calc(env(--radius-4) / 2);
  overflow: hidden;
}

.progressDone {
  background-color: hsl(env(--color-support-brand));
  border-radius: env(--radius-2);
  height: env(--space-8);
}

@media (min-width: env(--breakpoint-medium)) {
  .userBarWrapper, .emptyStateUsers {
    min-width: calc(env(--space-200) * 3 / 1.6);
  }

  .emptyStateUsers{
    height:100%;
  }
}
