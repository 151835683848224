.main{
  background-color: hsl(env(--color-grey-0));
  border-radius: env(--space-8);
  justify-content: center;
  padding: env(--space-32) ;
  min-width: calc(env(--space-200) * 3 / 2);
  border: env(--space-2) solid hsl(env(--color-grey-10));

}

.stats{
  height:  env(--space-128);
  width: env(--space-128);
  background-color: hsl(env(--color-orange-20));
  border-radius: 50%;  
}