.footer {
  background: #1b1d1d;
  color: white;
}

.footer .details {
  padding: 0 env(--space-80);
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 1fr 1fr;
  grid-template-columns: 25% 1fr 1fr;
}

.footer .details div {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.footer .disclaimer {
  border-top: env(--space-2) solid rgba(255, 255, 255, 0.08);
  padding: env(--space-32) env(--space-80);
  font-size: 70%;
  flex-direction: row;

}

.footer .link {
  font-weight: 700;
}

.footer .socialShare ul {
  list-style: none;
}

.footer .socialShare ul li {
  display: inline-block;
  padding: 0 env(--space-8);
}

.logoLink:hover,
.link:hover,
.footer a:hover,
.logo:hover,
.social:hover {
  opacity: 0.8;
  color: white !important;
  text-decoration: none !important;
}

.link,
.link:visited {
  color: white;
  text-decoration: none !important;
}

.logo {
  block-size: env(--space-200);
  inline-size: env(--space-200);
  position: relative;
  cursor: pointer;
}

.social {
  block-size: env(--space-16);
  inline-size: env(--space-16);
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 859px) {
  .footer .details {
    padding: env(--space-12) env(--space-40);
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: env(--space-40);
    overflow: hidden;
  }

  .logo {
    block-size: env(--space-80);
    inline-size: env(--space-104);
    position: relative;
    cursor: pointer;
  }

  .footer .disclaimer {
    padding: env(--space-32) env(--space-40);
    flex-direction: column;

  }

  .footer .socialShare {
    text-align: center;
  }

  .footer .socialShare ul {
    -webkit-padding-start: 0px;
    padding-inline-start: 0px;
  }
}
