
.checkbox {
  display: block;
  text-align: left;
  cursor: pointer;
      position: relative;
}

.checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: env(--space-24);
  width: env(--space-24);
  border: 1px solid #1c1e1e;
}

.checkbox:hover input[type="checkbox"] ~ .checkmark {
  background-color: #fec4b5;
  border: 1px solid #fec4b5;
}

.checkbox input[type="checkbox"]:checked ~ .checkmark {
  background-color: #ec7660;
  border: 1px solid #ec7660;
}

.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 7px;
  top: 2px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox label {
  text-align: left;
  margin-left: 40px;
  cursor: pointer;
  font-family: 'NHaasGroteskDSPro-55Rg', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
}