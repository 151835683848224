.wrapper {
  position: relative;
  width: 100%;;
  height: calc(env(--space-200)*1.4);
  background-color: hsl(env(--color-grey-0));
  border-radius: env(--radius-10);
  border: env(--space-2) solid hsl(env(--color-grey-10));
}

@media (min-width: env(--breakpoint-medium)) {
  .wrapper {
    position: relative;
    width: calc(env(--space-200)*3.25);
    height: calc(env(--space-200)*1.6);
    /* width:100%; */
  }
}
