.table {
  margin-top: env(--space-16);
  border-collapse: collapse;
}

thead .tr {
  display: none;
}

.tr {
  flex-wrap: wrap;
  display: flex;
  border: env(--space-2) solid hsl(env(--color-grey-10));
/* height: env(--space-48h);
align-items:center; */
        padding: 0.5rem;


  &[data-open='true'] {
    box-shadow: 0px 4px 20px 0px #0f0f0f1f;
    border-radius: env(--radius-10);

    &:not:first-child {
      border-top: env(--space-2) solid hsl(env(--color-grey-20));
      margin: 0 env(--space-16);
    }
  }
}

.td,
.th {
  margin: 0 env(--space-16) !important;
    width: 100%;
    height:env(--space-48);
    display:flex;
    align-items: center;
    justify-content: space-between;

  &[data-first='true'] {
    width: 100%;
  }

  &[data-first='false'] {
    display: none;
    align-items: flex-start;
    width: 100%;
  }

  &[data-open='true'] {
    display: block;

    &[data-first='true'] {
    display: flex;

    }

    &:not(:first-child):not(:last-child) {
      border-top: env(--space-2) solid hsl(env(--color-grey-20));
    }
  }

}

.iconDropdown {
  justify-content: center;
  display: flex;
  cursor: pointer;

  padding: env(--space-4);

  &:hover {
    background-color: hsl(env(--color-grey-10));
    border-radius: 50%;
  }
}

.tr,
.th,
.td,
.td p,
.td div {
  font-size: env(--font-size-14) !important;
  line-height: env(--font-size-18) !important;
  padding: 0;
}

.sortIcon {
  margin-left: env(--space-8);
  width: env(--space-12);
}

 .tableContainer{
      max-height: calc(env(--space-200)*3);
      overflow-y:scroll;
  }

  .emptyState{
    height:calc(env(--space-200)*2);
     max-height: calc(env(--space-200)*2);
      overflow-y:scroll;
      background-color: hsl(env(--color-grey-10));
  }

@media (min-width: env(--breakpoint-small)) {
  

  thead .tr {
    display: table-row;
  }

  .tr {
    display: table-row;
    border: none;
    border-bottom: calc(env(--space-2)/2) solid hsl(env(--color-grey-10));
height: env(--space-56);

    /* &[data-header="true"] {
      border-top: env(--space-2) solid hsl(env(--color-grey-10));
    } */

    &[data-open='true'] {
      box-shadow: none;
      border-radius: 0;
    }
  }

  .tdBefore {
    display: none;
  }

  .td,
  .th {
        padding:0 0.5rem;

     &[data-first='true'] {
      width: auto;
      /* display: none; */

      &:not(:first-child) {
        border-top: 0;
      }
    }

    &[data-open='true'] {
      &:not(:first-child) {
        border-top: 0;
      }
    }

    &[data-first='false'] {
      width: auto;
      display: table-cell;
      /* min-width: calc(env(--space-200)* 1.2); */
    }

     &[data-hide='true']{
       display: none;
     } 
  }
  
  .iconDropdown{display: none}
}
