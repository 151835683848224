.ManagerAdmin {
   display: flex;
  align-items: flex-start;
  /* justify-content: center; */
  min-height: 100vh;
  padding: env(--space-16);
  flex-direction: column; 
}

.section {
  display: flex;
  gap: env(--space-20);
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
      /* max-width: 30em; */
    width: 100%;

    &[data-logo="true"]{
          justify-content: flex-start;
    }
}

.card {
  max-width: calc(env(--space-72) * 7);
}

.title {
  margin-top: env(--space-40);
}

.title,
.description {
  margin-bottom: env(--space-24);
}

.description{
  color: hsl(env(--color-grey-50));

}

.buttons{
  display: flex;
  flex-direction: column;
}

.button {
  margin-right: env(--space-12);
  margin-bottom: env(--space-12);
  padding: 0 3rem!important;
}

/* .logoMobile {
 position: relative;
} */
/*
.logoDesktop {
  display: none !important;
  opacity: 0;
} */


@media (min-width: env(--breakpoint-small)) {
  .ManagerAdmin {
    align-items: center;
    justify-content: center;
  }

  /* .logoMobile {
    display: none !important;
    opacity: 0;
  }

  .logoDesktop {
    display: block !important;
    opacity: 1;
  } */

  .section {
    flex-direction: row;
    max-width: calc(env(--space-144) * 7);
    gap: env(--space-40);
  }

  .card {
    max-width: calc(env(--space-72) * 7);
  }

  .title {
    margin-top: env(--space-40);
  }

  .title,
  .description {
    margin-bottom: env(--space-24);
  }

  .buttons{
  flex-direction: row;
}


  .button {
    margin-right: env(--space-12);
  }
}
