/* svg {
  display: inline-block;
  vertical-align: middle;
	transform-origin: 50% 50%;
	animation: scale .6s;
  margin: 10px;
	
  /* text {
    font-family: "Helvetica", Arial, sans-serif;
    font-weight: bolder;
    font-size: 12px;
  } 
}*/
/*  
@keyframes scale {
	from { transform: scale(.5); }
	to { transform: scale(1); }
} */

.foreignObject {
    display: flex;
    align-content: center;
    width: 80px;
    height: 80px;
    align-items: center;
}