.wrapper {
  padding: env(--space-60)  env(--space-24);
  background-color: hsl(env(--color-support-peach));
  border-radius: env(--radius-10);
}

.image {
  display: none;
}


@media (min-width: env(--breakpoint-small)) {
  .wrapper {
    block-size: calc(env(--space-200)*1.75);
    inline-size: calc(env(--space-200)*2);
  }

  .image {
    display: block;
  }
}
