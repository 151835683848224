.nav {
    padding: 0 env(--space-40);
    margin: env(--space-8) 0;
    background: hsl(env(--color-support-white));
    color: hsl(env(--color-support-black));
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: env(--space-64);
}

.container {
    padding: env(--space-24);
    overflow-y: scroll;
    max-height: calc(100% - env(--space-104));
}

.companies {
    padding: env(--space-16) 0;
    height: 100%;
    overflow-y: scroll;
}

.company {
    cursor: pointer;
    border-radius: env(--radius-8);
    padding: env(--space-16);

    &:hover {
        background-color: hsl(env(--color-grey-5));
    }


    &[data-selected=true] {
        background: hsl(env(--color-grey-10));
    }
}

.iconSetting {
    justify-content: center;
    display: flex;
    cursor: pointer;
    /* width:env(--space-24) ;
height:env(--space-24) ; */
    /* padding: env(--space-4); */
    /* 
  &:hover {
    background-color: hsl(env(--color-grey-20));
    border-radius: 50%;
  } */
}

.logout {
    margin-top: env(--space-16);
}

.nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.nav ul li a {
    margin: 5px 15px;
}

.activator span {
    display: flex;
    flex-direction: row;
}

.profileTitle {}

.email {
    font-weight: 400;
}

.icon {
    block-size: env(--space-40);
    inline-size: env(--space-40);
    border-radius: calc(env(--space-40)/2);
    border: env(--space-2) solid hsl(env(--color-grey-20));
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: env(--breakpoint-medium)) {
    .nav {
        padding: env(--space-8) env(--space-40);
    }
}
