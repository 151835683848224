.container {
  position: relative;
}

.dropdown {
  background-color: hsl(env(--color-support-white));
  position: absolute;
  z-index: 100;
  margin-top: calc(-1 * env(--space-24));
  width: 100%;

  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  /* Box Shadow */

  box-shadow: 0px 4px 20px rgba(15, 15, 15, 0.12);
  border-radius: env(--radius-10);
  padding: env(--space-12);

  & > li {
    list-style-type: none;
    cursor: pointer;
  }
}
