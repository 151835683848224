.contactUs {
  overflow: hidden;
  justify-content: flex-end;
  background-color: #723349;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: env(--space-24);
}

.section {
  max-width: env(--breakpoint-extra-small);
}

.label {
  opacity: 0.7;
  color: hsl(env(--color-support-white));
  padding-bottom: env(--space-12);
}

.title {
  padding-bottom: env(--space-24);
  color: hsl(env(--color-support-white)) !important;
}

.image {
  display: flex;
  height: calc(env(--space-104) * 3);
  position: relative;
  width: 100vw;
}

.card {
  max-width: calc(env(--space-144) * 7 / 2);
  padding: env(--space-80) env(--space-24);
}

@media (min-width: env(--breakpoint-small)) {
  .contactUs {
    flex-direction: row;
  }

  .section {
    display: flex;
    flex-wrap: wrap;

    flex-direction: row;
    justify-content: center;
    max-width: calc(env(--space-144) * 7);
  }

  .card {
    max-width: calc(env(--space-72) * 7);
    padding: env(--space-80) 0;
  }

  .image {
    display: flex;
    width: 50vw;
    overflow: hidden;
    position: relative;
    height: calc(env(--space-200) * 2.5);
  }
}
