.headerSticky {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  position: fixed;
  background-color: hsl(env(--color-support-white));
  border-bottom: env(--space-2) solid hsl(env(--color-grey-10));

  &[data-sticky='true'] {
    @media (prefers-reduced-motion: no-preference) : {
      animation: content-show 200ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    /* opacity: 1; */
  }

  &[data-sticky='false'] {
    opacity: 0;
  }
}

.button {
  padding: 0 3rem!important;
}

.logo {
  display: none;
}

@media (min-width: env(--breakpoint-small)) {
  .logo {
    display: block; /* 
        block-size: env(--space-104);
    inline-size: env(--space-40); */
  }
}

@keyframes content-show {
  0% {
    opacity: 0%;
    /* transform: translate(-50%, -48%) scale(0.5); */
  }

  100% {
    opacity: 100%;
    /* transform: translate(-50%, -50%) scale(1); */
  }
}
