
.page {
  cursor: pointer;
  padding: 4px 10px;
/* block-size: env(--space-16);
inline-size: env(--space-16); */
border-radius: env(--space-12);
width:env(--space-24);
height:env(--space-24);
display: flex;
justify-content: center;
align-items: center;

font-size: env(--font-size-14);
font-style: normal;
font-weight: 400;



&:hover{
  background-color: hsl(env(--color-grey-5))
}

&[data-selected=true]{
   background-color: hsl(env(--color-support-black));
   color: hsl(env(--color-support-white));
  
}

  &:not(:first-child){

  }

  &[data-disabled=true]{
   cursor: default;
}

}



.icon {
  cursor: pointer;


  &[data-disabled=true]{
   cursor: default;
}

}

.prev,
.next {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  align-content: center;
}
