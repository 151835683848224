.main {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background-color: hsl(env(--color-support-burgundy));
  padding: env(--space-24) env(--space-24) env(--space-24) env(--space-24);
  color: white;
  align-items: center;
}


.confirmDelete{
  width: 100%;
  
  &>fieldset{
    margin:0;
  }
}

.deleteDialog{
  padding: env(--space-32)
}

.description{
  color:hsl(env(--color-grey-40));
  font-size: env(--font-size-14);
}

@media (min-width: env(--breakpoint-small)) {
  .main {
    flex-direction: row;
    padding: env(--space-72) env(--space-128) env(--space-72) env(--space-80);
    justify-content: space-between;
  }


  .confirmDelete{
    width: calc( env(--space-200)*3/2);
  }
}
