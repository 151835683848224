.wrapper {
  background-color: hsl(env(--color-support-white));
  padding-bottom: env(--space-16);
  margin: auto;
  inline-size: fit-content;
}

.imageWrapper {
  block-size: env(--space-144);
  inline-size: env(--space-128);
  position: relative;
  display: flex;
  border-radius: env(--radius-8);
  padding: env(--space-16);
  cursor: pointer;

  &:hover {
    background-color: hsl(env(--color-grey-5));

  }

  &[data-active='true'] {
    background-color: hsl(env(--color-grey-10));

  }
}

.image {
  display: flex;
  position: relative;


}
