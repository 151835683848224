.wrapper {
  background-color: hsl(env(--color-grey-0));
  border-radius: env(--radius-10);
  padding: env(--space-32);
  border: env(--space-2) solid hsl(env(--color-grey-10));
    flex-direction: column;
    
  &>div {
    width: 100%;
  }
}

.separator {
  margin: env(--space-12) 0;
}

.dot {
  height: env(--space-8);
  width: env(--space-8);
  border-radius: 50%;
  display: inline-block;

  &[data-month='past'] {
    background-color: hsl(env(--color-support-brand));
  }

  &[data-month='current'] {
    background-color: hsl(env(--color-support-burgundy));
  }
}


@media (min-width: env(--breakpoint-medium)) {
  .wrapper {
  width: calc(env(--space-200)*1.5);
  }
}
