.parent {
  display: flex;
  border-radius: env(--radius-8);
 flex-direction: column;
  align-items: center;

  &[data-background='burgundy'] {
    background-color: hsl(env(--color-support-burgundy));
  }

  &[data-background='grey'] {
    background-color: hsl(env(--color-support-support-light));
  }

  &[data-background='peach'] {
    background-color: hsl(env(--color-support-peach));
  }
  
}

.main {
  /* to add new color once inserted */
  padding: env(--space-24) env(--space-24) env(--space-24) env(--space-24);
  flex-direction: column;
}


.flexButton {
  /* to add new color once inserted */
  padding: env(--space-24) env(--space-40) env(--space-24) env(--space-24);
}

.image {
  position: relative;
  block-size: calc(env(--space-64)*2);
  inline-size: calc(env(--space-64)*2);
}

@media (min-width: env(--breakpoint-small)) {
  .main {
    /* to add new color once inserted */
    padding: env(--space-24) env(--space-24) env(--space-24) env(--space-24);
    flex-direction: row;
    flex: 0 0 75%;
  }

  .parent {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
